import React from "react";
import Helmet from 'react-helmet'
// import { Link } from "gatsby"
import Layout from "../components/layout";
// import Logo from "../_images/campaign-logo.svg";
import DOD from '../components/dods';
import Vimeo from '@u-wave/react-vimeo';
import AnimatedNumber from "animated-number-react";
import ScrollAnimation from 'react-animate-on-scroll';


// import StateSupport from '../_images/state-support.svg';
// import StudentPopulation from '../_images/student-population.svg';
// import Infrastructure from '../_images/infrastructure.svg';

var pageTitle = 'Raising $270 Million: A Titan Success Story';

var pageDesc = 'Cal State Fullerton celebrates achievements from its first-ever comprehensive philanthropic campaign.';

export default class About extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      planned: 0,
      gifts: 0,
      individual: 0,
      alumni: 0,
      scholarships: 0,
      corporations: 0,
      endowment: 0,
      assets: 0
    };
    // this.onVisible = this.onVisible.bind(this);
    this.donorImpactVisible = this.donorImpactVisible.bind(this);
    this.scrollElement = React.createRef();
    this.scrollTrigger = React.createRef();
  }

  decimalFormatValue = (value) => Number(value).toFixed(2);
  numberFormatValue(value) {
    value = Number(value).toFixed(2);
    value = Number(value).toLocaleString();
    return value;
  }

  donorImpactVisible = () => {
    this.setState({
      total: 270,
      planned: 87,
      gifts: 145704,
      individual: 71396,
      alumni: 42178,
      scholarships: 20.7,
      corporations: 34,
      endowment: 78.3,
      assets: 104.5
    });
  }

  scrollDonors = () => {

    // console.log('hello');
    console.log(this.scrollElement.current.scrollLeft);
    this.scrollElement.current.scrollLeft = this.scrollElement.current.scrollLeft + 400

  }

  render() {
    
    return (
  <React.Fragment>
  <Layout locationInfo={this.props.location}>
    <Helmet>
      <title>{pageTitle}</title>
      <body className="about" />
      <meta itemprop="name" content={pageTitle} />
      <meta name="twitter:title" content={pageTitle} />
      <meta property="og:title" content={pageTitle} />
      <meta name="description" content={pageDesc} />
      <meta itemprop="description" content={pageDesc} />
      <meta name="twitter:description" content={pageDesc} />
      <meta property="og:description" content={pageDesc} />
    </Helmet>
    <section className="content">
      <article className="fixed">
        <div className="wrap small">
          <h1 className="center">Raising $270 Million:<br />A Titan Success Story</h1>
          <p style={{textAlign: 'center'}}><strong><em>Cal State Fullerton celebrates achievements from its first-ever comprehensive philanthropic campaign.</em></strong></p>

          <p>To elevate the potential of its students, Cal State Fullerton publicly launched its first-ever comprehensive philanthropic campaign in March 2020. Thousands of donors, alumni, faculty, staff, students, community members, foundations and corporations came together to make that vision a reality — helping the university raise more than $270 million.</p>
 
          <p>With declining state funding, a significant student population and aging campus infrastructure, “It Takes a Titan: The Campaign for Cal State Fullerton” has been an opportunity for the Titan community to ensure students have the tools they need to succeed in a diverse workforce and rapidly changing global economy. The campaign invests in projects that enhance academic innovation, empower students, transform campus structures and enrich the greater community.</p>

          <aside className="notable-gifts">
            <h2>Notable Gifts</h2>

            <p><strong>$40 million</strong> unrestricted gift from philanthropist MacKenzie Scott to support “institutions successfully educating students who come from communities that have been chronically underserved”</p>
            <p><strong>$10 million</strong> planned gift from longtime supporters Nicholas and Lee Begovich to advance gravitational-wave, engineering and computer science research</p>
            <p><strong>$4 million</strong> bequest from alumnus Brian Rennie and wife, Lori, to support desert science studies, conservation and climate change research</p>
            <p><strong>$1.5 million</strong> planned gift from alumni Bill and Paula Purpura to be split between the College of Business and Economics and the College of Engineering and Computer Science</p>
            <p><strong>$1 million</strong> gift from alumnus Terry Giles to benefit CSUF’s Center for Leadership in the College of Business and Economics</p>
          </aside>
          
          <p>“When we launched the campaign, we set an aspirational goal of raising $175 million. Then the COVID-19 pandemic hit,” said Greg Saks, vice president for university advancement. “During one of the most trying times in our collective history, the Titan family continued to support student success and advance the work of the university — even raising additional funds for students hit hard by the pandemic.</p>
          
          <p>“Thanks to the deep-seated generosity of thousands of donors and alumni, not only did we reach our initial goal, but we surpassed it to raise an astounding $270 million by the close of 2022.”</p>
          
          <p>Since its founding in 1957, Cal State Fullerton has delivered on the promise of higher education. The university continues to be a national leader in providing access to quality education and strengthening social mobility for historically underrepresented students. Under the leadership of President Fram Virjee, CSUF renewed its focus on the principles of diversity, equity, inclusion and social justice and doubled down on its commitment to creating a community where all Titans can reach their full potential.</p>
          
          <p>“Reflecting on the campaign, what comes to mind are the stories of students directly impacted by your gifts — students who gained confidence, discovered their passions and developed the skills they need to be leaders in the county, nation and world,” said Saks. “This is what the ‘It Takes a Titan’ campaign has been about all along: empowering our students.</p>
          
          <p>“We are incredibly grateful to our supporters. You are the Titans it takes to ensure that Cal State Fullerton’s work will continue for years to come.”</p>

        </div>
      </article>
    </section>
    <section style={{padding: '50px 0', borderTop: '1px solid #EAE6E3', backgroundColor: '#EFEDE6'}}>
      <article className="fixed wrap">

        <Vimeo
          video="807735300"
          responsive
          showTitle="true"
          showByline="false"
          showPortrait="false"
        />

      </article>
    </section>
    <section id="donor-impact">
      {/* <VisibilitySensor onChange={this.onVisible}> */}
      <div className="wrap">
        <ScrollAnimation animateOnce={true} animateIn="fadeInUp" afterAnimatedIn={this.donorImpactVisible}>
        <h2 className="text-white">By the Numbers</h2>
        </ScrollAnimation>

        <div className="row m-t-20">
          <div className="col-4 col-6-sm">
            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
            <span className="impact-stat">
              <span className="impact-number">$<AnimatedNumber value={this.state.total}  delay={0} formatValue={this.numberFormatValue} duration={2000} />M</span>
              <span className="impact-desc">Total raised for “It Takes a Titan” campaign</span>
            </span>
            </ScrollAnimation>
          </div>
          <div className="col-4 col-6-sm">
            <ScrollAnimation animateOnce={true} delay={500} animateIn="fadeInUp">
            <span className="impact-stat">
              <span className="impact-number">$<AnimatedNumber value={this.state.planned}  delay={500} formatValue={this.numberFormatValue} duration={2000} />M</span>
              <span className="impact-desc">Planned giving commitments</span>
            </span>
            </ScrollAnimation>
          </div>
          <div className="col-4 col-6-sm">
            <ScrollAnimation animateOnce={true} delay={1000} animateIn="fadeInUp">
            <span className="impact-stat">
              <span className="impact-number"><AnimatedNumber value={this.state.gifts}  delay={1000} formatValue={this.numberFormatValue} duration={2000} /></span>
              <span className="impact-desc">Number of gifts given to the campaign</span>
            </span>
            </ScrollAnimation>
          </div>
        </div>
        <div className="row m-t-20">
          <div className="col-4 col-6-sm">
            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
            <span className="impact-stat">
              <span className="impact-number"><AnimatedNumber value={this.state.individual}  delay={0} formatValue={this.numberFormatValue} duration={2000} /></span>
              <span className="impact-desc">Number of individual donors</span>
            </span>
            </ScrollAnimation>
          </div>
          <div className="col-4 col-6-sm">
            <ScrollAnimation animateOnce={true} delay={500} animateIn="fadeInUp">
            <span className="impact-stat">
              <span className="impact-number"><AnimatedNumber value={this.state.alumni}  delay={500} formatValue={this.numberFormatValue} duration={2000} /></span>
              <span className="impact-desc">Number of alumni donors</span>
            </span>
            </ScrollAnimation>
          </div>
          <div className="col-4 col-6-sm">
            <ScrollAnimation animateOnce={true} delay={1000} animateIn="fadeInUp">
            <span className="impact-stat">
              <span className="impact-number">$<AnimatedNumber value={this.state.scholarships}  delay={1000} formatValue={this.numberFormatValue} duration={2000} />M</span>
              <span className="impact-desc">Total awarded in scholarships</span>
            </span>
            </ScrollAnimation>
          </div>
        </div>
        <div className="row m-t-20">
          <div className="col-4 col-6-sm">
            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
            <span className="impact-stat">
              <span className="impact-number">$<AnimatedNumber value={this.state.corporations}  delay={0} formatValue={this.numberFormatValue} duration={2000} />M</span>
              <span className="impact-desc">Total invested by corporations</span>
            </span>
            </ScrollAnimation>
          </div>
          <div className="col-4 col-6-sm">
            <ScrollAnimation animateOnce={true} delay={500} animateIn="fadeInUp">
            <span className="impact-stat">
              <span className="impact-number">$<AnimatedNumber value={this.state.endowment}  delay={500} formatValue={this.numberFormatValue} duration={2000} />M</span>
              <span className="impact-desc">Endowment increase during campaign (as of June 2022)</span>
            </span>
            </ScrollAnimation>
          </div>
          <div className="col-4 col-6-sm">
            <ScrollAnimation animateOnce={true} delay={1000} animateIn="fadeInUp">
            <span className="impact-stat">
              <span className="impact-number">$<AnimatedNumber value={this.state.assets}  delay={1000} formatValue={this.numberFormatValue} duration={2000} />M</span>
              <span className="impact-desc">Total increase in assets of Philanthopic Foundation</span>
            </span>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      {/* </VisibilitySensor> */}
    </section>
    {/* <section className="inspired">
      <div className="wrap">
        <div className="row">
          <div className="col-6 logowrap">
            <blockquote>
              <p>“Perhaps a true measure of success is not one person scaling a mountain but how many others that person brings along.”</p>
              <p className="byline">– Irena Praitis, CSUF professor of English, comparative literature and linguistics</p>
            </blockquote>
          </div>
          <div className="col-6 logotext">
            <img src={Logo} alt="It Takes a Titan Logo" />
            <p>Inspired by the snowcapped San Gabriel mountains on the campus horizon, our campaign logo evokes Titans’ determination, persistence and ascendance to success, all while blazing a path for others.</p>
          </div>
        </div>
      </div>
    </section> */}
    <DOD />
  </Layout>
  </React.Fragment>
    );
  }
}
