import React from "react";

export default (props) => {

  var name
  var title
  var phone
  var email

  var name2 = null;
  var title2 = null;
  var phone2 = null;
  var email2 = null;
  
  if (props.college === 'hhd') {
    name = 'Carlos Leija';
    title = 'Associate Vice President';
    phone = '657-278-4869';
    email = 'cleija@fullerton.edu';
  } else if (props.college === 'arts') {
    name = 'Carlos Leija';
    title = 'Associate Vice President';
    phone = '657-278-4869';
    email = 'cleija@fullerton.edu';
  } else if (props.college === 'athletics') {
    name = 'Jeffrey Gilstrap';
    title = 'Senior Director of Development, Athletics';
    phone = '657-278-4516';
    email = 'jgilstrap@fullerton.edu ';
  } else if (props.college === 'ecs' || props.college === 'arboretum') {
    name = 'Michael Karg';
    title = 'Executive Director of Development';
    phone = '657-278-3348';
    email = 'mkarg@fullerton.edu';
  } else if (props.college === 'nsm') {
    name = 'Michael Karg';
    title = 'Executive Director of Development';
    phone = '657-278-3348';
    email = 'mkarg@fullerton.edu';

    name2 = 'Toni Miller';
    title2 = 'Senior Director of Development';
    phone2 = '657-278-3308';
    email2 = 'tamiller@fullerton.edu';
  } else if (props.college === 'education') {
    name = 'Lauren Sieven';
    title = 'Senior Director of Development';
    phone = '657-278-4320';
    email = 'lasieven@fullerton.edu';
  } else if (props.college === 'students' || props.college === 'comm') {
    name = 'Katie McGill';
    title = 'Executive Director of Development';
    phone = '657-278-8231';
    email = 'kmcgill@fullerton.edu';
  } else if (props.college === 'hss') {
    name = 'Melissa Martinez';
    title = 'Senior Director of Development';
    phone = '657-278-4847';
    email = 'melissamartinez@fullerton.edu';
  } else if (props.college === 'cbe') {
    name = 'Chrissy Vaughn';
    title = 'Director of Development';
    email = 'cmvaughn@Fullerton.edu';
    phone = '657-278-7433';
  } else {
    name = 'University Advancement';
    title = null;
    phone = '657-278-5287';
    email = 'giving@fullerton.edu';
  }

  if (title) {
    var titlePlain = title;
    var title = <span>{title}<br /></span>;
  } else {
    title = null;
  }

  if (title2) {
    var titlePlain2 = title2;
    var title2 = <span>{title2}<br /></span>;
  } else {
    title2 = null;
  }

  if (name2) {
    var twoDOD = {
      height: 'auto'
    }
  } else {
    var twoDOD = null;
  }

  if(props.layout == 2) {
    return (
      <React.Fragment>
        <p>For more information about this investment opportunity and how your contribution will be used, contact:</p>
        <p>
          <strong>{name}</strong><br />
          {title}
          <a href={"mailto:" + email}>{email}</a><br />
          {phone}
        </p>
        {name2 &&
          <p>
            <strong>{name2}</strong><br />
            {title2}
            <a href={"mailto:" + email2}>{email2}</a><br />
            {phone2}
          </p>
        }
      </React.Fragment>
    )
  } else if (props.layout == 'name-only') {
    return (
      <React.Fragment>
        {name}
      </React.Fragment>
    )
  } else if (props.layout == 'title-only') {
    return (
      <React.Fragment>
        {titlePlain}
      </React.Fragment>
    )
  } else if (props.layout == 'email-hidden-input') {
    return (
      <React.Fragment>
        <input type="hidden" name="dodEmail" value={email} />
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <section className="contact-dod" style={twoDOD}>
          <div className="wrap">
            <p>
              <strong>For more information, contact:</strong><br />
              {name}<br />
              {title}
              {phone} &nbsp; | &nbsp; <a href={"mailto:" + email}>{email}</a>
            </p>
            {name2 &&
              <p>
                or<br />
                {name2}<br />
                {title2}
                {phone2} &nbsp; | &nbsp; <a href={"mailto:" + email2}>{email2}</a>
              </p>
            }
          </div>
        </section>
      </React.Fragment>
    )    
  }
} 